.header{
	height:185px;
	background-image: url(../images/header-bg.png);
	background-repeat: repeat;
}
.top-base{
	font-size: 12px;
	height: 32px;
	line-height: 32px;
	background-image: url(../images/top-base-bg.png);
	background-repeat: repeat;
	/* 解决顶部top 设置row后 会显示横拉条的问题 */
	margin-right: 0px;
	margin-left: 0px;
	color: #333333;
}
.top-base a{
	color: #666666;
	text-decoration: none;
}
.top-base a:hover{
	color: #333333;
	text-decoration: none;
}
.login-f{
	display:-ms-flexbox;
	display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:end;justify-content:flex-end;
}
.login-icon{
	margin: 0 5px;vertical-align: middle;display:block;
}
.logo-text{
	font-size: 12px;
}
.row-base{
	/* 解决顶部top 设置row后 会显示横拉条的问题 */
	margin-right: 0px;
	margin-left: 0px;
}

.logo{
	margin: 20px 0;
}
.search-f{
	display: -ms-flexbox;
	display: flex;-ms-flex-pack:end;justify-content:flex-end;-ms-flex-align:center;align-items:center;-ms-flex-wrap: wrap;flex-wrap: wrap; padding-left: 100px;;
}
.search-div{
	height: 37px;line-height: 37px;-ms-flex-preferred-size: 100%;flex-basis: 100%;
}
.search-text{
	height: 20px;line-height: 20px;-ms-flex-preferred-size: 100%;flex-basis: 100%;
	margin-top: 3px;
}
.search-border{
	padding: 0;
	border: #C02C38 1px solid;
	border-radius: 3px;
	width: 400px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-ms-flex-align:center;
	align-items:center;
}
.search-btn{
	margin: 0;width: 100px;height: 37px;line-height: 37px;font-size: 16px;color: #fff;background-color: #C02C38 ;text-align: center;
}
.search-input{
	height: 37px;line-height: 37px;background-color:transparent;border:0;outline: none;padding-left:20px;width:280px;font-size: 16px;color: #666666 ;
}
.search-text a{
	margin: 0 7px;
	color: #666666 ;
	text-decoration: none;
}
.search-text a:hover{
	color: #333333;
	text-decoration: none;
}
 .nav-li{
     margin-right: 40px !important;
 }

.footer{
  background-color:#ededed;
}
.footer-nav{
  background-color: #ededed;border-bottom: 1px #e4e4e4 solid;height: 85px;line-height: 85px;
}
.footer-nav a{
  font-size: 16px;
  color: #333;
  text-decoration: none;
}
.footer-nav a:hover{
  color: #999999;
}
.footer-ul{
  display: flex;justify-content: space-between;width: 100%;list-style: none;
}
.footer-ul li{
  display: list-item;
  text-align: -webkit-match-parent;
}

.footer-icon{
  display:-ms-flexbox; display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:end;justify-content:flex-end;height: 85px;
}
.footer-icon img{
  width:30px;height: 24px middle;display:block;
}
.footer-content{
  background-color: #ededed;
  text-align: center;
  color: #999999;
  font-size: 14px;
}

.footer-content a{
  color: #333;
  text-decoration: none;
}

.footer-content a:hover{
  color: #999999;
}